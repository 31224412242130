import fetch from 'node-fetch';

export async function getStableDiffusionImage(prompt, style) {
  try {
    const firebaseFunctionURL = "https://us-central1-oniichat-2c310.cloudfunctions.net/generateImage";
    console.log("Prompt:", prompt);
    console.log("Style:", style);

    const response = await fetch(firebaseFunctionURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        prompt: prompt,
        style: style,
        subscription: "Ultimate"
      })
    });

    const data = await response.json();
    console.log("Response data:", data);

    if (data.outputLink) {
      console.log("Output link:", data.outputLink);
      return data.outputLink;
    } else {
      console.error("Output link not found in response");
      return null;
    }
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}